/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react'
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { globalHistory } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Swiper from 'react-id-swiper'
// import Layout from '../Layout/index'
// icons
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'

import iconLocal from '../../images/home/iconLocal.svg'
import Ilojas from '../../images/home/header/icon-lojas.svg'
import iconPin from '../../images/home/icon-pin.svg'
import lupa from '../../images/home/modalHome/lupaVermelha.svg'
import moment from 'moment'
// scss
import './buscarloja.scss'

const OfertasSearch = ({ lojas, current, setCurrentMarket, setValueForm }) => {
  console.log(lojas, current)
  const [markets, setMarkets] = useState(lojas.nodes.map(node => (node)))
  const [searchLabel, setSearchLabel] = useState(false)
  const [displaySearchContainer, setDisplaySearchContainer] = useState(false)

  const [itemsToShow, _setItemsToShow] = useState(10)
  const itemsToShowRef = React.useRef(itemsToShow)
  const setItemsToShow = data => {
    itemsToShowRef.current = data
    _setItemsToShow(data)
  }

  const path = globalHistory.location.pathname

  function onSuccess (position) {
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    console.log('success')
    calcDistance(pos)
  }

  function onError (error) {
    var txt
    switch (error.code) {
      case error.PERMISSION_DENIED:
        txt = 'Location permission denied'
        break
      case error.POSITION_UNAVAILABLE:
        txt = 'Location position unavailable'
        break
      case error.TIMEOUT:
        txt = 'Location position lookup timed out'
        break
      default:
        txt = 'Unknown position.'
    }
    console.error(txt)
  }

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
      const insercao = document.getElementsByTagName('script')[0]
      insercao.parentNode.insertBefore(script, insercao)
      script.addEventListener('load', e => {
        console.log('Geocoder is now available!')
      })
    }

    if (document.querySelector('#search-container-home')) {
      document.querySelector('#search-container-home').addEventListener('scroll', handleInfinityScroll)
    }
    enableScroll()
  })

  const enableScroll = () => {
    document.getElementsByTagName('html')[0].style.overflow = 'auto'
    document.getElementsByTagName('html')[0].style.position = 'relative'
    document.getElementsByTagName('html')[0].style.width = '100%'
  }

  const handleInfinityScroll = (event) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.target
    const delta = Math.round(scrollTop + offsetHeight)
    if (delta >= scrollHeight - 5) {
      setItemsToShow(itemsToShowRef.current + 10)
    }
  }

  const useCurrentLocation = () => {
    setDisplaySearchContainer(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  const calcDistance = (p) => {
    console.log('>> CalcDistance')
    const myLat = p.lat
    const myLng = p.lng
    let mkts = lojas.nodes.map(node => {
      const LatLoja = node.lat
      const LngLoja = node.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      node.distance = parseFloat(dist.toFixed(1))
      return node
    })
    mkts = mkts.sort((a, b) => (a.distance - b.distance))
    console.log(mkts)
    setMarkets(mkts)
  }

  const resetInfinityScroll = () => {
    setItemsToShow(10)
    document.querySelector('#search-container-home').scrollTop = 0
  }

  const handleSearch = (e) => {
    setDisplaySearchContainer(true)
    e.preventDefault()

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({
      address: e.target[0].value,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
    (results, status) => {
      console.log('google results', { results: results, status: status })

      if (status === 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }

        setSearchLabel(results[0].formatted_address)
        calcDistance(pos)
        resetInfinityScroll()
      } else {
        console.log('FAIL')
      }
    })
  }

  const handleMarketSelected = (market) => {
    setCurrentMarket(market)
    setValueForm()
    setDisplaySearchContainer(false)
    setSearchLabel(false)

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('currentMarket', JSON.stringify(market))
      document.getElementById('currentMarket').innerHTML = market.name

      if (path.match(/\/lojas\/[a-z]/)) {
        navigate(`/lojas/${market.slug}`)
      }
      const currentStore = typeof window !== 'undefined' ? window.localStorage.getItem('currentMarket') : ''
      const currentMarket = currentStore ? JSON.parse(currentStore) : ''
      // setHasSelectedStore(currentMarket)
      // handleTabloide(currentMarket)
    }
  }

  return (
    <div className=''>
      <div className="row mt-3">
        <div className="col-lg-7 d-flex flex-column justify-content-center align-items-center">
          <form className='w-100 font-size-1'>
            <label htmlFor="input-search"></label>
            <input type="text" name="input-search" id="input-search" className="pl-3 inputLoja p-2" placeholder="Digite seu CEP, Cidade ou Bairro" />
            <button onClick={() => handleSearch(event)} type="submit" name="button" className="border-0 bg-transparent outline-0" aria-label="search-button">
              <img src={lupa} className="imgLupa position-absolute" alt="search icon" />
            </button>
          </form>
        </div>
        <div className='col-lg-auto col-12 text-center align-self-center pb-lg-4 p-0'>
          <span className='font-size-13'>ou</span>
        </div>
        <div className="col">
          <button className="btn btn-red-1 p-2 btn-block text-white font-size-1 inputLoja bg-dia-red" onClick={() => useCurrentLocation()}>
            <FontAwesomeIcon icon={faMapMarked} className="fa-icon-button" />
            Usar minha localização atual
          </button>
        </div>
      </div>
      <div className="col-md-12">
        {
          searchLabel && (
            <div className="w-65 mx-auto">
              <h4 className="paragrafoLojas text-center font-size-1 mt-4">Resultados de Busca para "{searchLabel}"</h4>
            </div>
          )
        }
      </div>
      <div className="row d-flex align-items-center mb-3">
      </div>
      {displaySearchContainer &&
        <div className="row">
          <div className="col-12">
            <div id="search-container-home">
              {markets.slice(0, itemsToShow).map(market => {
                return (
                  <div className="lojasCard mt-2 mr-sm-2" key={`A${market.storeNumber}`}>
                    <div className="row align-items-center">
                      <div className="col-lg-9 col-md-12 ">
                        <p className="font-weight-bold">{market.name}</p>
                        {market.distance && <p>Distância: {market.distance} Km</p>}
                        <p>{market.address} <br />
                          {market.district} - {market.city} - {market.uf}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-12">
                        <button type="button" className="btn btn-red-1 w-100 py-2" onClick={() => handleMarketSelected(market)}>
                          Selecionar
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <Link to='/lojas/' className="btn btn-red-1 font-Yanone w-100 py-2 mt-3">
              Ver Todas as Lojas
            </Link>
          </div>
        </div>
      }
    </div>
  )
}

const BuscarLoja = ({ current, setCurrentMarket, setValueForm }) => {
  const query = useStaticQuery(
    graphql`{
      lojas: allCloudOfertasLoja {
        nodes {
          id
          slug
          storeNumber
          address
          cep
          city
          district
          lat
          lng
          name
          reference
          status
          uf
          whatsapp
          mondayOpen
          mondayClose
          holidayOpen
          holidayClose
          sundayOpen
          sundayClose
          services {
            icon
            name
          }
          tariff {
            id
            name
          }
        }
      }
    }
`
  )

  return (
    <>
      <OfertasSearch current={current} lojas={query.lojas} setCurrentMarket={setCurrentMarket} setValueForm={setValueForm} />
    </>
  )
}

export default BuscarLoja
